@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .no-scroll-bar::-webkit-scrollbar {
    @apply hidden
  }

  .no-scroll-bar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}