.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 0%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
}
.modal-backdrop.show {
  height: 100%;
}

.modal {
  position: absolute;
  top: 0%;
  left: 50%;
  max-width: 500px;
  height: 20vh;
  width: 80%;
  transform: translate(-50%, -102%);
  background-color: rgba(250, 36, 36, 0.2);
  backdrop-filter: blur(20px);
    transition: top 0.25s linear;
    border-radius: 10px;
}

.modal.show {
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-start;
}

.modal__header {
  height: 5vh;
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  padding: 5px;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.modal__content {
  height: 10vh;
  color: #ffffff;
  padding: 10px;
}

.modal__footer {
  height: 4vh;
  text-align: right;
  padding: 0 20px 0 0;
}

.modal__footer button {
  background-color: rgba(0, 155, 0, 0.4);
  padding: 6px;
  border-radius: 5px;
  border: 0;
  font-size: 1rem;
    cursor: pointer;
    color: #ffffff;
}

.modal__footer button:hover,
.modal__footer button:active {
    background-color: rgba(0, 255, 0, 0.7);
    color: #000000;
}
